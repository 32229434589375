import { Autocomplete, Box, Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Paper, Select, Stack, TextField, Tooltip, Typography, IconButton, Menu, FormHelperText } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { DateTimePicker, LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { ImagePaths } from '../../../../Utility/ImagePaths'
import CommonGridHeader, { CommonDeleteButton, CommonEditButton, CommonPatientCard, CommonPatientDetails, CommonPopUp, ConsultantPatientCard, DeletePopup } from '../../../../Components/Common Components/CommonComponents'
import './patientDetails.css'
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import { DateTime } from 'luxon'
import { getCachevalue, localGetItem } from '../../../../Utility/Services/CacheProviderService'
import { CheckAccessFunc, calculateBMI, formatDateAndTime, formatDate, calculateWeeksAndDays } from '../../../../Components/CommonFunctions/CommonFunctions'
import { Colors } from '../../../../Styles/Colors'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Loader from '../../../../Components/Loader';

class NurseNotes extends Component {

    constructor(props) {
        super(props)
        this.state = {
            height: "",
            weight: "",
            bp_d: "",
            bp_s: "",
            temp: "",
            pulse_oxi: "",
            pulse_rate: "",
            blood_glucose: "",
            comments: "",
            nurseName: {
                "nurse": "",
                "staff_id": ""
            },
            vitalDate: new Date(),
            page: 0,
            pageSize: 10,
            nurseNotesData: [],
            isEdit: false,
            attenderData: [],
            unit: "F",
            patientData: {},
            attender_name: "",
            attender_mobile: "",
            NurseList: [],
            //vitals IP
            IpPatients: {},
            vitalData: [],
            vital_attender_mobile: "",
            vital_attender_name: "",
            vital_doctor_name: "",
            vital_doctor_id: "",
            vital_id: "",
            deletePopup: false,
            isHistoryClicked: false,
            vitalHistory: [],
            checkVitals: false,
            withOutAppointment: {},
            OTDetails: [],
            ClinicID: "",
            bmi: '',
            obs_ExpectedDeliveryDate: null,
            obs_LastMenstrualPeriod: null,
            gestational_weeks: "",
            gestational_days: "",
        }
    }

    componentDidMount() {
        var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
        var selectedClinic = JSON.parse(localGetItem("SelectedDoctorClinicId"))
        if(selectedClinic){
            this.setState({
                ClinicID : selectedClinic
            },() => {
                this.GetNurseList()
            })
        }
        if (this.props.fromVitals || this.props.is_From_IpNotes) {
            let IPPatientsData = getCachevalue("IppatientData")
            let IpPatients = JSON.parse(IPPatientsData)
            let appointmentDetail = getCachevalue("withoutAppointment");
            let details = JSON.parse(appointmentDetail);
            if (details?.patient_id === IpPatients?.patient_id) {
                this.setState({
                    withOutAppointment: details
                }, () => {
                    this.getVitalPatientData()
                    this.getVitalData()
                })
            }
            this.setState({
                IpPatients: IpPatients
            }, () => {
                this.getVitalPatientData()
                this.getVitalData()
            })
        }
        else if (myUser?.module_name === "ot_management" || selectedClinic) {
            let patientDatas = getCachevalue("OTpatientData")
            let patients = JSON.parse(patientDatas)
            this.setState({
                OTDetails: patients
            }, () => {
                this.getVitalData()
            })
        }
        else {
            let patientDatas = getCachevalue("patientData")
            let patients = JSON.parse(patientDatas)
            this.setState({
                patientData: patients
            }, () => {
                if (this.state.patientData?.appointment_id) {
                    this.getNurseNotesData()
                }
            })
        }
        if (myUser?.module_name === "ot_management" && !selectedClinic) {
            if (myUser?.clinic_id) {
                this.setState({
                    ClinicID: myUser?.clinic_id
                }, () => { this.GetNurseList() })
            }
        } else if(!selectedClinic) {
            this.GetNurseList()
        }
    }

    GetNurseList = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_STAFF_LIST + `?clinic_id=${this.state.ClinicID}`)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            NurseList: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    onChangeHandler = (event) => {
        const { name, value } = event.target
        let num = CommonValidation.DecimalNumber(value)
        let alpha = CommonValidation.alphabetOnly(value)
        switch (name) {
            case "height":
                if ((num && value >= 1 && value <= 230) || value === "") {
                    this.setState({ height: value }, () => {
                        let bmi = calculateBMI(this.state.weight, this.state.height)
                        this.setState({
                            bmi: bmi?.toFixed(2)
                        })
                    })
                }
                break;
            case "weight":
                if ((num && value >= 1 && value <= 500) || value === "") {
                    this.setState({ weight: value }, () => {
                        let bmi = calculateBMI(this.state.weight, this.state.height)
                        this.setState({
                            bmi: bmi?.toFixed(2)
                        })
                    })
                }
                break;
            case "bp_d":
                if ((num && value >= 1 && value <= 200) || value === "") {
                    this.setState({ bp_d: value })
                }
                break;
            case "bp_s":
                if ((num && value >= 1 && value <= 250) || value === "") {
                    this.setState({ bp_s: value })
                }
                break;
            case "temp":
                if ((this.state.unit === "F" && num && value >= 1 && value <= 248) || value === "") {
                    this.setState({ temp: value })
                } else if ((this.state.unit === "C" && num && value >= 1 && value <= 200) || value === "") {
                    this.setState({ temp: value })
                }
                break;
            case "pulse_oxi":
                if ((num && value >= 1 && value <= 100) || value === "") {
                    this.setState({ pulse_oxi: value })
                }
                break;
            case "pulse_rate":
                if ((num && value >= 1 && value <= 150) || value === "") {
                    this.setState({ pulse_rate: value })
                }
                break;
            case "blood_glucose":
                if ((num && value >= 1 && value <= 500) || value === "") {
                    this.setState({ blood_glucose: value })
                }
                break;
            case "gestational_weeks":
                if ((num && value >= 1 && value <= 45) || value === "") {
                    this.setState({ gestational_weeks: value })
                }
                break;
            case "gestational_days":
                if ((num && value >= 1 && value <= 6) || value === "") {
                    this.setState({ gestational_days: value })
                }
                break;
            default:
                this.setState({
                    [name]: value
                })
                break;
        }
    }

    checkVitals = (name) => {
        let valitate = false;
        switch (name) {
            case "height":
                if (this.state.height >= 230) {
                    valitate = true;
                }
                break;
            case "weight":
                if (this.state.weight > 150) {
                    valitate = true;
                }
                break;
            case "bp_d":
                if (this.state.bp_d > 80 && this.state.bp_d <= 100) {
                    valitate = true;
                } else if (this.state.bp_d > 100) {
                    valitate = true;
                }
                break;
            case "bp_s":
                if (this.state.bp_s > 120 && this.state.bp_s <= 150) {
                    valitate = true;
                } else if (this.state.bp_s > 150) {
                    valitate = true;
                }
                break;
            case "pulse_oxi":
                if (this.state.pulse_oxi > 1 && this.state.pulse_oxi < 90) {
                    valitate = true;
                }
                break;
            case "pulse_rate":
                if (this.state.pulse_rate > 100) {
                    valitate = true;
                }
                break;
            case "blood_glucose":
                if (this.state.blood_glucose > 140 && this.state.blood_glucose <= 250) {
                    valitate = true;
                } else if (this.state.blood_glucose > 250) {
                    valitate = true;
                }
                break;
            default:
                valitate = false;
                break;
        }

        return valitate
    }
    vitalsText = (name) => {
        let text = ""
        switch (name) {
            case "height":
                if (this.state.height >= 230) {
                    text = " Abnormal"
                } else if (this.state.height >= 50) {
                    text = "Normal"
                }
                break;
            case "weight":
                if (this.state.weight > 150) {
                    text = "Abnormal"
                } else if (this.state.weight >= 2.7) {
                    text = "Normal"
                }
                break;
            case "bp_d":
                if (this.state.bp_d > 80 && this.state.bp_d <= 100) {
                    text = "Risk (prehypertension)"
                } else if (this.state.bp_d > 100) {
                    text = "High (hypertension)"
                } else if (this.state.bp_d >= 60) {
                    text = "Normal"
                }
                break;
            case "bp_s":
                if (this.state.bp_s > 120 && this.state.bp_s <= 150) {
                    text = "Risk (prehypertension)"
                } else if (this.state.bp_s > 150) {
                    text = "High (hypertension)"
                } else if (this.state.bp_s >= 100) {
                    text = "Normal"
                }
                break;
            case "pulse_oxi":
                if (this.state.pulse_oxi > 1 && this.state.pulse_oxi < 90) {
                    text = "Abnormal"
                } else if (this.state.pulse_oxi > 90 && this.state.pulse_oxi <= 100) {
                    text = "Normal"
                }
                break;
            case "pulse_rate":
                if (this.state.pulse_rate > 100) {
                    text = "Abnormal"
                } else if (this.state.pulse_rate >= 60) {
                    text = "Normal"
                }
                break;
            case "blood_glucose":
                if (this.state.blood_glucose > 140 && this.state.blood_glucose <= 250) {
                    text = "Prediabetes"
                } else if (this.state.blood_glucose > 250) {
                    text = "Diabetes"
                } else if (this.state.blood_glucose >= 31.8) {
                    text = "Normal"
                }
                break;
            default:
                break;
        }
        return text;
    }

    inputTextBox = (value, name, unit, input, width, labelName, style) => {
        const { t } = this.props
        return (
            <TextField
                InputProps={input ? {
                    endAdornment: <InputAdornment position="end" className='eMed_nur_inputAdor'>{t(unit)}</InputAdornment>,
                } : {}}
                sx={{ width: style }}
                size='small'
                error={this.checkVitals(name)}
                helperText={this.vitalsText(name)}
                className={style !== "" ? 'eMed_nur_txtBox' : ""}
                fullWidth={width}
                placeholder={labelName}
                name={name}
                autoComplete='off'
                value={value}
                onChange={(e) => { this.onChangeHandler(e) }}
            />
        )
    }

    renderObgynDatePicker = (stateKey, disable) => {
        let states = this.state
        return (
            <Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        value={states[stateKey] ? states[stateKey] : null}
                        inputFormat='DD-MM-YYYY'
                        views={["year", "month", "day"]}
                        maxDate={stateKey === "obs_LastMenstrualPeriod" ? new Date() : null}
                        minDate={stateKey === "obs_ExpectedDeliveryDate" ? new Date() : null}
                        disabled={disable}
                        onChange={(newDate) => {
                            if (stateKey === "obs_LastMenstrualPeriod") {
                                states[stateKey] = newDate
                                states["obs_ExpectedDeliveryDate"] = new Date(new Date(newDate).getTime() + 40 * 7 * 24 * 60 * 60 * 1000);
                                // Calculate weeks and days between newDate and today
                                const { weeks, days } = calculateWeeksAndDays(new Date(newDate), new Date());
                                states["gestational_weeks"] = weeks;
                                states["gestational_days"] = days;
                            } else {
                                states[stateKey] = newDate
                            }
                            this.setState({ states })
                        }}
                        renderInput={(params) => <TextField
                            autoComplete='off'
                            onKeyDown={(e) => e.preventDefault()}
                            size='small' {...params} />}
                    />
                </LocalizationProvider>
            </Box>)
    }

    NurseSelection = () => {
        const { t } = this.props
        return (
            <Autocomplete
                size='small'
                options={this.state.NurseList}
                getOptionLabel={(option) => option.nurse}
                value={this.state.nurseName}
                onChange={(event, newValue) => {
                    this.setState({
                        nurseName: newValue
                    })
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("NurseName")}
                        placeholder={t("NurseName")}
                    />
                )}
            />
        )
    }

    getNurseNotesData = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_NURSE_NOTES + `?appointment_id=${this.state.patientData.appointment_id}`)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            nurseNotesData: response.data.data,
                            attender_name: response.data.patient_data[0].attender_name,
                            attender_mobile: response.data.patient_data[0].attender_mobile
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    postNurseNotesData = () => {
        try {
            let date = ""; let data = {}
            if (
                (this.state.height !== "") ||
                (this.state.weight !== "") ||
                (this.state.bp_s !== "") ||
                (this.state.bp_d !== "") ||
                (this.state.temp !== "") ||
                (this.state.pulse_oxi !== "") ||
                (this.state.blood_glucose !== "") ||
                (this.state.pulse_rate !== "") ||
                (this.state.gestational_days !== "") || (this.state.gestational_weeks !== "") ||
                (this.state.obs_LastMenstrualPeriod !== null) || (this.state.obs_ExpectedDeliveryDate !== null)
            ) {
                let startDate = new Date(this.state.vitalDate)
                date = this.state.vitalDate ? DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd hh:mm:ss a") : ""
                data = {
                    "appointment_id": this.state.patientData?.appointment_id,
                    "nurse": this.state.nurseName.staff_id,
                    "height": +this.state.height,
                    "weight": +this.state.weight,
                    "bp_s": +this.state.bp_s,
                    "bp_d": +this.state.bp_d,
                    "temperature": +this.state.temp,
                    "temperature_unit": this.state.unit,
                    "pulse_oximetry": +this.state.pulse_oxi,
                    "blood_sugar": +this.state.blood_glucose,
                    "pulse_rate": +this.state.pulse_rate,
                    "comments": this.state.comments,
                    "vital_entered_time": date,
                    'bmi': this.state.height && this.state.weight ? this.state.bmi : null,
                    "gestational_age_days": this.state.gestational_days ? this.state.gestational_days : 0,
                    "gestational_age_weeks": this.state.gestational_weeks ? this.state.gestational_weeks : 0,
                    "last_menstrual_period": this.state.obs_LastMenstrualPeriod ? DateTime.fromJSDate(new Date(this.state.obs_LastMenstrualPeriod)).toFormat("yyyy-MM-dd") : null,
                    "expected_date_of_delivery": this.state.obs_ExpectedDeliveryDate ? DateTime.fromJSDate(new Date(this.state.obs_ExpectedDeliveryDate)).toFormat("yyyy-MM-dd") : null,
                }
                if (this.state.isEdit) {
                    data['id'] = this.state.selectedId
                }
                if (date === "Invalid DateTime") {
                    this.errorMessage("Enter Date and Time")
                } else {
                    this.LoaderFunction(true)
                    RestAPIService.create(data, Serviceurls.FO_NURSE_NOTES)
                        .then((response) => {
                            if (response.data.status === "success") {
                                this.getNurseNotesData()
                                this.onClearHandler()
                                this.setState({ isEdit: false })
                                this.LoaderFunction(false)
                                this.successMessage(response.data.message)
                            }
                        }).catch((error) => {
                            if (error?.response?.data?.message) {
                                this.LoaderFunction(false)
                                this.errorMessage(error.response.data.message)
                            } else {
                                this.LoaderFunction(false)
                                this.errorMessage(error.message)
                            }
                        })
                }
            }
            else {
                this.errorMessage("Please, Add Atleast One Field")

            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onEditHandler = (values) => {
        this.state.nurseName.nurse = values?.row?.nurse_name === null ? "" : values?.row?.nurse_name
        this.state.nurseName.staff_id = values?.row?.nurse_id
        this.setState({
            isEdit: true,
            selectedId: values?.row?.id,
            height: values?.row?.height_cm,
            weight: values?.row?.weight_kg,
            bp_s: values?.row?.blood_pressure_systolic,
            bp_d: values?.row?.blood_pressure_diastolic,
            blood_glucose: values?.row?.blood_sugar,
            pulse_oxi: values?.row?.pulse_oximetry,
            pulse_rate: values?.row?.pulse_rate,
            comments: values?.row?.comments,
            vitalDate: values?.row?.vital_entered_time ? values?.row?.vital_entered_time : "",
            nurseName: this.state.nurseName.nurse === null ? {
                "nurse": "",
                "staff_id": ""
            } : this.state.nurseName,
            temp: values?.row?.temperature,
            unit: values?.row?.temperature_unit,
            vital_id: values?.row?.id,
            bmi: values?.row?.bmi,
            obs_ExpectedDeliveryDate: values?.row?.expected_date_of_delivery,
            obs_LastMenstrualPeriod: values?.row?.last_menstrual_period,
            gestational_weeks: values?.row?.gestational_age_weeks,
            gestational_days: values?.row?.gestational_age_days,
        }, () => {
            let bmi = calculateBMI(this.state.weight, this.state.height)
            this.setState({
                bmi: bmi?.toFixed(2)
            })
        })
    }

    getVitalPatientData = () => {
        let admission_id = this.state.IpPatients?.id || this.state.withOutAppointment?.ip_admission_id;
        let url = `${Serviceurls.FO_PATIENT_DETAILS}?admission_id=${admission_id}`;
        if (this.props.is_From_IpNotes && this.state.ClinicID) {
            url = `${url}&clinic_id=${this.state.ClinicID}`
        }
        try {
            RestAPIService.getAll(url)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            vital_attender_name: response.data.data[0].attender_name,
                            vital_attender_mobile: response.data.data[0].attender_mobile,
                            vital_doctor_name: response.data.data[0].doctor_name,
                            vital_doctor_id: response.data.data[0].doctor_id
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getVitalData = () => {
        let admission_id = this.state.IpPatients?.id || this.state.withOutAppointment?.ip_admission_id || this.state.OTDetails?.ip_admission;
        try {
            RestAPIService.getAll(Serviceurls.FO_IP_CLINICAL_NOTES_VITAL + `?ip_admission_id=${admission_id}` + `&clinic_id=${this.state.ClinicID}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            vitalData: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    postVitalData = () => {
        try {
            let startDate = new Date(this.state.vitalDate)
            let today = new Date();
            let date = this.state.vitalDate ? DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd hh:mm:ss a") : DateTime.fromJSDate(today).toFormat("yyyy-MM-dd hh:mm:ss a")
            let data = {
                "nurse_id": this.state.nurseName === null ? "" : this.state.nurseName.staff_id,
                "doctor_id": this.state.OTDetails?.doctor_id ? this.state.OTDetails.doctor_id : this.state.vital_doctor_id,
                "patient_id": this.state.OTDetails?.patient_id ? this.state.OTDetails.patient_id : this.state.IpPatients?.patient_id,
                "ip_admission_id": this.state.OTDetails?.ip_admission ? this.state.OTDetails.ip_admission : this.state.IpPatients?.id,
                "vital_entered_time": date,
                "height_cm": +this.state.height,
                "weight_kg": +this.state.weight,
                "blood_pressure_diastolic": +this.state.bp_d,
                "blood_pressure_systolic": +this.state.bp_s,
                "temperature": +this.state.temp,
                "temperature_unit": this.state.unit,
                "pulse_oximetry": +this.state.pulse_oxi,
                "pulse_rate": +this.state.pulse_rate,
                "blood_sugar": +this.state.blood_glucose,
                "comments": this.state.comments,
                "clinic_id": this.state.ClinicID,
                'bmi': this.state.height && this.state.weight ? this.state.bmi : null,
                "gestational_age_days": this.state.gestational_days ? this.state.gestational_days : 0,
                "gestational_age_weeks": this.state.gestational_weeks ? this.state.gestational_weeks : 0,
                "last_menstrual_period": this.state.obs_LastMenstrualPeriod ? DateTime.fromJSDate(new Date(this.state.obs_LastMenstrualPeriod)).toFormat("yyyy-MM-dd") : null,
                "expected_date_of_delivery": this.state.obs_ExpectedDeliveryDate ? DateTime.fromJSDate(new Date(this.state.obs_ExpectedDeliveryDate)).toFormat("yyyy-MM-dd") : null,
            }
            if (this.state.isEdit) { data['vital_id'] = this.state.vital_id }
            if (
                this.state.height !== "" ||
                this.state.weight !== "" ||
                this.state.bp_s !== "" ||
                this.state.bp_d !== "" ||
                this.state.temp !== "" ||
                this.state.pulse_oxi !== "" ||
                this.state.blood_glucose !== "" ||
                this.state.pulse_rate !== "" ||
                (this.state.gestational_days !== "") || (this.state.gestational_weeks !== "") ||
                (this.state.obs_LastMenstrualPeriod !== null) || (this.state.obs_ExpectedDeliveryDate !== null)
            ) {
                this.LoaderFunction(true)
                RestAPIService.create(data, Serviceurls.FO_IP_CLINICAL_NOTES_VITAL)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            this.getVitalData()
                            this.onClearHandler()
                            this.setState({ isEdit: false })
                            this.LoaderFunction(false)
                            this.successMessage(response.data.message)
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.LoaderFunction(false)
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.LoaderFunction(false)
                            this.errorMessage(error.message)
                        }
                    })
            } else {
                this.errorMessage("Please, Add Atleast One Value")
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onDeleteHandler = (value) => {
        if (!this.state.isEdit) {
            this.setState({
                deletePopup: true,
                rowValue: value?.row,
                delSelectedId: value?.row?.id
            })
        }
    }

    popupClose = () => {
        this.setState({ deletePopup: false })
    }

    deleteVitalData() {
        try {
            RestAPIService.delete(Serviceurls.FO_IP_CLINICAL_NOTES_VITAL + `?vital_id=` + this.state.delSelectedId + `&clinic_id=` + this.state.ClinicID)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.successMessage(response.data.message)
                        this.getVitalData()
                        this.setState({ deletePopup: false })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onClearHandler = () => {
        this.setState({
            height: "", weight: "", bp_s: "",
            bp_d: "", blood_glucose: "", pulse_oxi: "",
            pulse_rate: "",
            nurseName: {
                "nurse": "",
                "staff_id": ""
            },
            comments: "",
            temp: "", vitalDate: new Date(),
            obs_ExpectedDeliveryDate: null,
            obs_LastMenstrualPeriod: null,
            gestational_weeks: "",
            gestational_days: "",
        })
    }

    getVitalHistoryData = (id) => {
        try {
            let url = Serviceurls.FO_IP_HISTORY + `?condition=vitals&row_id=${id}`;
            if (this.state.ClinicID) {
                url = `${url}&clinic_id=${this.state.ClinicID}`
            }
            RestAPIService.getAll(url).
                then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            vitalHistory: response.data.data,
                            isHistoryClicked: true
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getVitalHistory() {
        const { t } = this.props
        this.state.vitalHistory.forEach((element, index) => element.sno = index + 1)
        const columns = [
            { field: "modified_by_to", headerName: "Modified By", flex: 0.125 },
            {
                field: "modified_date", headerName: "Modified Date And Time", flex: 0.125,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.modified_date ? params?.row?.modified_date?.length > 10 ?
                    <Tooltip placement='top' title={params?.row?.modified_date} arrow><div>{params?.row?.modified_date.slice(0, 10) + '...'}</div></Tooltip> : params?.row?.modified_date : '-'}</Box>)
            },
            {
                field: 'height_cm_from', headerName: "Previous Height | Weight", flex: 0.125,
                renderCell: (params) => (<Box component={'div'}>{(`${params?.row?.height_cm_from}` || `${params?.row?.weight_kg_from}`) ?
                    (`${params?.row?.height_cm_from ? params?.row?.height_cm_from : '-'} | ${params?.row?.weight_kg_from ? params?.row?.weight_kg_from : '-'}`) : '-'}</Box>)
            },
            {
                field: "temperature_from", headerName: "Previous Temperature", flex: 0.125,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.temperature_from ? params?.row?.temperature_from?.length > 10 ?
                    <Tooltip placement='top' title={params?.row?.temperature_from} arrow><div>{`${params?.row?.temperature_from.slice(0, 10) + '...'} ${params?.row?.temperature_unit_from}`}</div></Tooltip> : `${params?.row?.temperature_from} ${params?.row?.temperature_unit_from}` : '-'}</Box>)
            },
            {
                field: "pulse_rate_from", headerName: "Previous Pulse Rate", flex: 0.125,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.pulse_rate_from ? params?.row?.pulse_rate_from?.length > 10 ?
                    <Tooltip placement='top' title={params?.row?.pulse_rate_from} arrow><div>{params?.row?.pulse_rate_from.slice(0, 10) + '...'}</div></Tooltip> : params?.row?.pulse_rate_from : '-'}</Box>)
            },
            {
                field: "blood_sugar_from", headerName: "Previous Blood Glucose", flex: 0.125,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.blood_sugar_from ? params?.row?.blood_sugar_from?.length > 10 ?
                    <Tooltip placement='top' title={params?.row?.blood_sugar_from} arrow><div>{params?.row?.blood_sugar_from.slice(0, 10) + '...'}</div></Tooltip> : params?.row?.blood_sugar_from : '-'}</Box>)
            },
            {
                field: "pulse_oximetry_from", headerName: "Previous Pulse Oximetry", flex: 0.125,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.pulse_oximetry_from ? params?.row?.pulse_oximetry_from?.length > 10 ?
                    <Tooltip placement='top' title={params?.row?.pulse_oximetry_from} arrow><div>{params?.row?.pulse_oximetry_from.slice(0, 10) + '...'}</div></Tooltip> : params?.row?.pulse_oximetry_from : '-'}</Box>)
            },
            {
                field: "comments_from", headerName: "Previous Description", flex: 0.125,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.comments_from ? params?.row?.comments_from?.length > 10 ?
                    <Tooltip placement='top' title={params?.row?.comments_from} arrow><div>{params?.row?.comments_from.slice(0, 10) + '...'}</div></Tooltip> : params?.row?.comments_from : '-'}</Box>)
            }
        ]
        return (
            <Box component={'div'} className='eMed_vital_grid_history'>
                <DataGrid
                    rows={this.state.vitalHistory}
                    columns={columns}
                    getRowId={(row) => row.sno}
                    density='compact'
                    hideFooter
                    components={{
                        NoRowsOverlay: () => (
                            <Box className='eMed_conf_nodata'>
                                {t("NoRecordsFound")}
                            </Box>
                        )
                    }}
                />
            </Box>
        )
    }

    historyPopUpClose() {
        this.setState({
            isHistoryClicked: false,
            vitalHistory: []
        })
    }

    MenuItem = (data) => {
        const { t } = this.props
        const list = [
            { value: "History", label: t("History") },
        ]
        return (
            <div>
                <Tooltip title={t("More")} placement='top' arrow>
                    <Button
                        className='eMed_vital_more'
                        onClick={(e) => {
                            this.setState({
                                anchorEl: e.currentTarget,
                                selectedRow: data.id,
                                isSelected: true
                            })
                        }}
                    >
                        <MoreVertIcon />
                    </Button>
                </Tooltip>
                {(this.state.isSelected && this.state.selectedRow == data.id) ?
                    <Menu
                        anchorEl={this.state.anchorEl}
                        open={Boolean(this.state.anchorEl)}
                        onClose={() => {
                            this.setState({ anchorEl: null, })
                        }}>
                        <MenuItem onClick={() => { this.setState({ anchorEl: null }, () => { this.getVitalHistoryData(data?.row?.id) }) }}>
                            {t("History")}
                        </MenuItem>
                    </Menu> : null}
            </div>
        )
    }

    renderNurseNotesHeader = () => {
        const { t } = this.props
        let data = {
            lineOne: t("AttenderName"),
            lineTwo: `${this.state.attender_name ? this.state.attender_name : "-"}`,
            lineFour: t('ContactNumber'),
            LineFive: `${this.state.attender_mobile ? this.state.attender_mobile : "-"}`
        }
        let vital_attender_data = {
            lineOne: t("AttenderName"),
            lineTwo: `${this.state.vital_attender_name ? this.state.vital_attender_name : this.state.OTDetails.attender_name ? this.state.OTDetails.attender_name : "-"}`,
            lineFour: t('ContactNumber'),
            LineFive: `${this.state.vital_attender_mobile ? this.state.vital_attender_mobile : this.state.OTDetails.attender_mobile ? this.state.OTDetails.attender_mobile : "-"}`
        }
        let vital_doctor_data = {
            image: ImagePaths.Doctor_Icon.default,
            height: "2.3vw",
            width: "2.3vw",
            lineOne: t("ConsultantName"),
            lineTwo: `${this.state.vital_doctor_name ? this.state.vital_doctor_name : this.state.OTDetails.admitting_consultant ? this.state.OTDetails.admitting_consultant : "-"}`
        }
        return (
            <Box display={'flex'} marginBottom={"0.5dvw"}>
                <Box className='eMed_nur_notes_details'>
                    {this.props.fromVitals ?
                        <CommonPatientDetails data={this.state.IpPatients} showDetailed={true} /> :
                        this.state.OTDetails.patient_id ?
                            <CommonPatientDetails data={this.state.OTDetails} /> :
                            <CommonPatientDetails data={this.state.patientData} />
                    }
                </Box>
                {this.props.fromVitals ?
                    <>
                        <CommonPatientCard details={vital_attender_data} showDetailed={true} />
                        <ConsultantPatientCard name={vital_doctor_data} width={this.props.is_From_IpNotes ? "20dvw" : null}/>
                    </> :
                    this.state.OTDetails.patient_id ?
                        <>
                            <CommonPatientCard details={vital_attender_data} showDetailed={true} />
                            <ConsultantPatientCard name={vital_doctor_data} />
                        </>
                        :
                        <CommonPatientCard details={data} showDetailed={true} />
                }
            </Box>
        )
    }

    historyPopUpClose() {
        this.setState({
            isHistoryClicked: false,
            SelectedHistory: [],
            isSelected: false
        })
    }

    MenuItem = (data) => {
        const { t } = this.props
        const multi_option = [
            { value: "history", label: t("History") }
        ]
        return (
            <div>
                <Tooltip title={t("More")} placement='top' arrow>
                    <Button
                        className='eMed_vital_more'
                        onClick={(e) => {
                            this.setState({
                                anchorEl: e.currentTarget,
                                selectedRow: data.id,
                                isSelected: true
                            })
                        }}
                    >
                        <MoreVertIcon />
                    </Button>
                </Tooltip>
                {(this.state.isSelected && this.state.selectedRow == data.id) ?
                    <Menu
                        anchorEl={this.state.anchorEl}
                        open={Boolean(this.state.anchorEl)}
                        onClose={() => {
                            this.setState({ anchorEl: null, })
                        }}>
                        <MenuItem onClick={() => { this.setState({ anchorEl: null }, () => { this.getVitalHistoryData(data?.row?.id) }) }}>
                            {t("History")}
                        </MenuItem>
                    </Menu> : null}
            </div>
        )
    }


    renderNurseLeftSide = () => {
        const { t } = this.props
        this.state.nurseNotesData.forEach((element, index) => { element.sno = index + 1 })
        this.state.vitalData.forEach((element, index) => element.sno = index + 1)
        let data = this.props.fromVitals || (this.state.OTDetails?.ip_admission != null) ? this.state.vitalData : this.state.nurseNotesData
        var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
        var UserData = myUser
        const checkuserAccess = CheckAccessFunc("front_office", "In Patients", "Clinical Notes", "Vitals", "SubTab")?.editAccess ?
            this.state.withOutAppointment?.is_ip
            : true;
        
        const columns = [
            {
                field: "vital_entered_time", headerName: t("Date"), flex: 0.090, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.vital_entered_time && params?.row?.vital_entered_time !== "Invalid DateTime" ? params?.row?.vital_entered_time?.length > 8 ?
                    <Tooltip placement='top' title={formatDateAndTime(params?.row?.vital_entered_time)} arrow><div>
                        {formatDateAndTime(params?.row?.vital_entered_time).slice(0, 8) + "..."}</div></Tooltip> : formatDateAndTime(params?.row?.vital_entered_time) : "-"}</Box>)
            },
            {
                field: "height_cm", headerName: `${t("Height")} (cm)`, flex: 0.090, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.height_cm ? params?.row?.height_cm : "-"}</Box>)
            },
            {
                field: "weight_kg", headerName: `${t("Weight")} (kg)`, flex: 0.090, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.weight_kg ? params?.row?.weight_kg : "-"}</Box>)
            },
            {
                field: "blood_pressure_systolic", headerName: `${t("BP")} (mm/Hg)`, flex: 0.090, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.blood_pressure_systolic ?
                    `${params?.row?.blood_pressure_systolic}/${params?.row?.blood_pressure_diastolic}` : "-"}</Box>)
            },
            {
                field: "temperature", headerName: `${t("Temperature")}`, flex: 0.090, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.temperature ?
                    `${params?.row?.temperature}  ${params?.row?.temperature_unit}` : "-"}</Box>)
            },
            {
                field: "pulse_oximetry", headerName: `${t("SpO2")}`, flex: 0.090, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.pulse_oximetry ? params?.row?.pulse_oximetry : "-"}</Box>)
            },
            {
                field: "pulse_rate", headerName: `${t("PulseRate")}/min`, flex: 0.090, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.pulse_rate ? params?.row?.pulse_rate : "-"}</Box>)
            },
            {
                field: "blood_sugar", headerName: `${t("BloodGlucose")} (mg/DL)`, flex: 0.090, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.blood_sugar ? params?.row?.blood_sugar : "-"}</Box>)
            },
            ...(this.state.patientData?.doctor_speciality === "OBSTETRICS & GYNECOLOGY" || this.state.IpPatients?.doctor_speciality === "OBSTETRICS & GYNECOLOGY" ? [
            {
                field: "last_menstrual_period", headerName: `${t("Last Menstrual Period")}`, flex: 0.090, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.last_menstrual_period ? params?.row?.last_menstrual_period?.length > 8 ?
                    <Tooltip placement='top' title={formatDate(params?.row?.last_menstrual_period)} arrow><div>
                        {formatDate(params?.row?.last_menstrual_period).slice(0, 8) + "..."}</div></Tooltip> : formatDate(params?.row?.last_menstrual_period) : "-"}</Box>)
            }] : []),
            {
                field: "comments", headerName: t("Comments"), flex: 0.090,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.comments ? params?.row?.comments : "-"}</Box>)
            },
            {
                field: "nurse_name", headerName: t("NurseName"), flex: 0.090,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.nurse_name ? params?.row?.nurse_name?.length > 9 ?
                    <Tooltip arrow><div>{params?.row?.nurse_name.slice(0, 9) + "..."}</div></Tooltip> : params?.row?.nurse_name : "-"}</Box>)
            },
            {
                field: "action", headerName: "Action", flex: 0.090, hideable: false, headerAlign: "center", align: "center", sortable: false, hideable: false,
                renderCell: (params) => {
                    const isDocCanEdit = this.props.is_From_IpNotes ? !(myUser?.doctor_id === params.row.doctor_id) : checkuserAccess;
                    return(<Box>{
                    this.props.fromVitals ?
                        <Box display={'flex'} alignItems={'center'} justifyContent={'center'} paddingRight={'0.5vw'}>
                            <CommonEditButton size='1.5vw' disable = {isDocCanEdit} onClick={this.onEditHandler.bind(this, params)} />
                            <CommonDeleteButton size='1.5vw' disable = {isDocCanEdit} onClick={this.onDeleteHandler.bind(this, params)} />
                            {this.MenuItem(params)}
                        </Box> :
                        this.state.OTDetails?.ip_admission != null ?
                            <Box display={'flex'} alignItems={'center'} justifyContent={'center'} paddingRight={'0.5vw'}>
                                <CommonEditButton size='1.5vw' onClick={this.onEditHandler.bind(this, params)} />
                                <CommonDeleteButton size='1.5vw' onClick={this.onDeleteHandler.bind(this, params)} />
                            </Box> :
                            // disable={params?.row?.sno !== 1} ====> removed due to multiple edit needed
                            this.state.nurseNotesData ? <CommonEditButton size='1.5vw' onClick={this.onEditHandler.bind(this, params)} /> : ""
                }</Box>)}
            },
        ]
        return (
            <Paper elevation={1} component={'div'} className={(this.props.fromVitals || UserData?.module_name === 'nurse') ? 'eMed_vital_table' : (UserData?.module_name === 'front_office' && !UserData?.is_opt) ? 'eMed_vital_table1' : 'eMed_nur_table'}>
                <DataGrid
                    rows={data}
                    columns={columns}
                    getRowId={(row) => row.id}
                    page={this.state.page}
                    pageSize={this.state.pageSize}
                    localeText={{
                        toolbarColumns: "",
                        toolbarDensity: "",
                        toolbarExport: "",
                        toolbarFilters: "",
                        toolbarExportPrint: ""
                    }}
                    components={{
                        Toolbar: CommonGridHeader.CommonToolBar,
                        NoRowsOverlay: () => (
                            <Stack className='eMed_conf_nodata'>
                                {t("NoRecordsFound")}
                            </Stack>
                        )
                    }}
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                comments: false
                            }
                        }
                    }}
                    disableSelectionOnClick
                    onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                    onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                    rowsPerPageOptions={[10, 20, 30]}
                    pagination
                    headerHeight={40}
                    hideFooter={this.props.fromVitals}
                />
            </Paper>
        )
    }

    bmiHelperText = (bmi) => {
        try {
          let message = null
          if (bmi <= 18.5) {
            message = 'Mild Thinness'
          } else if (bmi > 18.5 && bmi <= 25) {
            message = 'Normal'
          } else if (bmi > 25 && bmi <= 30) {
            message = 'Overweight'
          } else if (bmi > 30) {
            message = 'Obesity'
          }
          return message;
        } catch (error) {
          this.errorMessage(error.message)
        }
      }

    renderNurseRightSide = () => {
        const { t } = this.props
        var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
        var UserData = myUser
        const currentDate = new Date();
        const states = this.state;
        const admissionDate = states?.IpPatients?.admitted_date
            ? new Date(states.IpPatients?.admitted_date)
            : states?.IpPatients?.admission_date
                ? new Date(states.IpPatients?.admission_date)
                : currentDate;
        const dischargeDate = states.IpPatients?.discharge_date && (new Date(states.IpPatients?.discharge_date) <= currentDate)  ?
            new Date(states.IpPatients?.discharge_date) : currentDate;
        return (
            <Paper elevation={1} component={'div'} className={(this.props.fromVitals || UserData?.module_name === 'nurse') ? 'eMed_vital_right_card' : (UserData?.module_name === 'front_office' && !UserData?.is_opt) ? 'eMed_vital_right_card1' : 'eMed_nur_right_card'}>
                <Typography component={'div'} fontWeight={600} color={Colors.SecondaryText} className='eMed_nur_rgt_title'>{t("Vitals")}</Typography>
                <Box component={'div'} className={(this.props.fromVitals || UserData?.module_name === 'nurse') ? 'eMed_vital_right_txtbox': (UserData?.module_name === 'front_office' && !UserData?.is_opt)? 'eMed_vital_right_txtbox1' : 'eMed_nur_right_txtbox'}>
                    <Typography component={'span'} className='eMed_nur_inner_content'>{t("VitalSign")}</Typography>
                    <Box component={'div'} display="flex" flex={'row'} className="eMed_textBox_container">
                        <Typography component={'span'} className='eMed_input_label'>{t("Height")}</Typography>
                        <Box component={'span'} className="eMed_input_txtbox">{this.inputTextBox(this.state.height, "height", "cm", true)}</Box>
                    </Box>
                    <Box component={'div'} display="flex" flex={'row'}>
                        <Typography component={'span'} className='eMed_input_label'>{t("Weight")}</Typography>
                        <Box component={'span'} className="eMed_input_txtbox">{this.inputTextBox(this.state.weight, "weight", "Kg", true)}</Box>
                    </Box>
                    {(this.state.height && this.state.weight) ?
                        <Box component={'div'}
                            sx={{ backgroundColor: this.state.bmi < 18.5 ? '#FFCCCB' : ((this.state.bmi >= 18.5) && (this.state.bmi <= 25)) ? 'lightgreen' : ((this.state.bmi > 25) && (this.state.bmi <= 30)) ? '#FBB917' : '#FF6863' }}
                            display={'flex'} borderRadius={'5px'} mt={'0.5vw'} p={'0vw 0.5vw'} justifyContent={'space-between'} alignItems={'center'} height={'2.5vw'}>
                            <Typography fontWeight={600} fontSize={'0.9vw'}>BMI</Typography>
                            <FormHelperText>
                                <Typography fontWeight={600} fontSize={'0.8vw'}>{this.bmiHelperText(this.state.bmi)}</Typography>
                            </FormHelperText>
                            <Typography fontWeight={600} fontSize={'0.9vw'}>{this.state.bmi}</Typography>
                        </Box> : null}
                    <Box component={'div'} display="flex" flex={'row'}>
                        <Typography component={'span'} className='eMed_input_label'>{t("BP_S")}</Typography>
                        <Box component={'span'} className="eMed_input_txtbox">{this.inputTextBox(this.state.bp_s, "bp_s", "mm/Hg", true)}</Box>
                    </Box><Box component={'div'} display="flex" flex={'row'}>
                        <Typography component={'span'} className='eMed_input_label'>{t("BP_D")}</Typography>
                        <Box component={'span'} className="eMed_input_txtbox">{this.inputTextBox(this.state.bp_d, "bp_d", "mm/Hg", true)}</Box>
                    </Box>
                    <Box component={'div'} display="flex" flex={'row'}>
                        <Typography component={'span'} className='eMed_input_label'>{t("Temp")}</Typography>
                        <Box component={'div'} className=" eMed_input_txtbox">
                            <TextField
                                size='small'
                                className='eMed_temp_txt'
                                name='temp'
                                value={this.state.temp}
                                onChange={(e) => this.onChangeHandler(e)}
                            />
                            <FormControl sx={{ width: "4.5vw" }} size='small'>
                                <InputLabel>{t("Unit")}</InputLabel>
                                <Select
                                    label={t("Unit")}
                                    name='unit'
                                    value={this.state.unit}
                                    onChange={(e) => { this.onChangeHandler(e) }}
                                >
                                    <MenuItem value={"F"}>&#xb0;F</MenuItem>
                                    <MenuItem value={"C"}>&#xb0;C</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                    <Box component={'div'} display="flex" flex={'row'}>
                        <Typography component={'span'} className='eMed_input_label'>{t("SpO2")}</Typography>
                        <Box component={'span'} className="eMed_input_txtbox">{this.inputTextBox(this.state.pulse_oxi, "pulse_oxi", "%", true)}</Box>
                    </Box>
                    <Box component={'div'} display="flex" flex={'row'}>
                        <Typography component={'span'} className='eMed_input_label'>{t("PulseRate")}</Typography>
                        <Box component={'span'} className="eMed_input_txtbox">{this.inputTextBox(this.state.pulse_rate, "pulse_rate", "/min", true)}</Box>
                    </Box>
                    <Box component={'div'} display="flex" flex={'row'}>
                        <Typography component={'span'} className='eMed_input_label'>{t("BloodGlucose")}</Typography>
                        <Box component={'span'} className="eMed_input_txtbox">{this.inputTextBox(this.state.blood_glucose, "blood_glucose", "mg/DL", true)}</Box>
                    </Box>
                    {(this.state.patientData?.doctor_speciality === "OBSTETRICS & GYNECOLOGY" || this.state.IpPatients?.doctor_speciality === "OBSTETRICS & GYNECOLOGY") ? 
                        <><Box component={'div'} display="flex" flex={'row'}>
                        <Typography component={'span'} className='eMed_input_label'>{t("Last Menstrual Period")}</Typography>
                        <Box component={'span'} className="eMed_input_txtbox">{this.renderObgynDatePicker("obs_LastMenstrualPeriod")}</Box>
                    </Box>
                    <Box component={'div'} display="flex" flex={'row'}>
                        <Typography component={'span'} className='eMed_input_label'>{t("Expected date of delivery")}</Typography>
                        <Box component={'span'} className="eMed_input_txtbox">{this.renderObgynDatePicker("obs_ExpectedDeliveryDate")}</Box>
                    </Box>
                    <Box component={'div'} width={"100%"} display="flex" flex={'row'}>
                        <Typography component={'span'} className='eMed_input_label'>{t("Gestational Age")}</Typography>
                        <Box component={'div'} className="eMed_input_txtbox" display="flex">
                            <Box component={'span'} marginRight={"0.4dvw"}>{this.inputTextBox(this.state.gestational_weeks, "gestational_weeks", "Weeks", true, "eMed_nur_gestTxtBox")}</Box>
                            <Box component={'span'} >{this.inputTextBox(this.state.gestational_days, "gestational_days", "Days", true, "eMed_nur_gestTxtBox")}</Box>
                        </Box>
                    </Box></> : null}
                    <Box component={'div'} display="flex" flex={'row'}>
                        <Typography component={'span'} className='eMed_input_label'>{t("Comments")}</Typography>
                        <Box component={'span'} className="eMed_input_txtbox">{this.inputTextBox(this.state.comments, "comments", "", false, true, "Enter Comments")}</Box>
                    </Box>
                    <Box component={'div'} display="flex" flex={'row'}>
                        <Typography component={'span'} className='eMed_input_label'>{t("NurseName")}</Typography>
                        <Box component={'span'} className="eMed_input_txtbox">{this.NurseSelection()}</Box>
                    </Box>
                    <Box component={'div'} display="flex" flex={'row'} marginBottom="1vw">
                        <Box component={'span'} sx={{ backgroundColor: Colors.ThemeLightColor }} className="eMed_Time_Style">
                            <Typography component={'span'} color={"gray"} marginLeft="0.5vw">{t("VitalDate")}</Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                    inputFormat='DD-MM-YYYY & hh:mm A'
                                    value={this.state.vitalDate}
                                    name={"vitalDate"}
                                    minDate={(this.props.fromVitals || this.props.is_From_IpNotes)
                                        ? admissionDate : null}
                                    maxDate={(this.props.fromVitals || this.props.is_From_IpNotes)
                                        ? dischargeDate : null}
                                    onChange={(newDate) => {
                                        if (newDate === null) {
                                            this.setState({
                                                vitalDate: new Date()
                                            })
                                        } else {
                                            this.setState({ vitalDate: newDate })
                                        }
                                    }}
                                    renderInput={(params) => <TextField size='small'
                                        {...params}
                                        fullWidth
                                        variant='standard'
                                        sx={{ marginTop: "1.5vw", marginLeft: "0.5vw", marginRight: "0.5vw", width: "21.5vw" }}
                                    />}
                                />
                            </LocalizationProvider>
                        </Box>
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_nur_btn_card'>
                    <Button variant='outlined' size='small'
                        className='eMed_nur_btn_one'
                        disabled={this.state.withOutAppointment?.is_ip}
                        onClick={() => { this.onClearHandler() }}>{t("Clear")}</Button>
                    <Button variant='contained' className='eMed_nur_btn_two' emed_tid="AddCompleted_Btn"
                        size='small'
                        disabled={this.state.isLoader ? true : this.props.fromVitals ? (CheckAccessFunc("front_office", "In Patients", "Clinical Notes", "Vitals", "SubTab") ? this.state.withOutAppointment?.is_ip : true) : (CheckAccessFunc("front_office", "Home", "Nurse Notes", null, "Tab").editAccess ? this.state.withOutAppointment?.is_ip : true)}
                        onClick={() => { this.props.fromVitals || (this.state.OTDetails?.ip_admission != null) ? this.postVitalData() : this.postNurseNotesData() }}>{t("AddCompleted")}</Button>
                </Box>
            </Paper>
        )
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key = false) => {
        this.setState({
            isLoader: key
        })
    }

    render() {
        const { t } = this.props
        var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
        var UserData = myUser
        return (
            <Box component={'div'} className={((UserData?.module_name === 'front_office' && !UserData?.is_opt) || this.props.fromVitals) ? 'eMed_nur_container1' : 'eMed_nur_container'}>
                <Box component={'div'}>
                    {this.renderNurseNotesHeader()}
                </Box>
                <Box component={'div'} margin= {"0.625dvw"}>
                    <Grid container spacing={1.5} className={(this.props.fromVitals || UserData?.module_name === 'nurse') ? 'eMed_vital_Container' : (UserData?.module_name === 'front_office' && !UserData?.is_opt) ? 'eMed_nur_grid_container1' : 'eMed_nur_grid_container'}>
                        <Grid item xs={8.7} className={(this.props.fromVitals || UserData?.module_name === 'nurse') ? 'eMed_vital_left' : 'eMed_nur_left'}>
                            {this.renderNurseLeftSide()}
                        </Grid>
                        <Grid item xs={3.3} className={(this.props.fromVitals || UserData?.module_name === 'nurse') ? 'eMed_vital_right' : 'eMed_nur_right'}>
                            {this.renderNurseRightSide()}
                        </Grid>
                    </Grid>
                </Box>
                {this.state.deletePopup ? <DeletePopup
                    DeletTitle={`${t("deleteMsg")} ${this.props.fromVitals ? "vitals" : "nurse notes"}?`}
                    deleteAlertPopupClose={this.popupClose.bind(this)}
                    removeData={this.deleteVitalData.bind(this)}
                /> : null}
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {(this.state.isHistoryClicked) ?
                    <CommonPopUp width={"75vw"}
                        backgroundColor={Colors.white}
                        popUpClose={this.historyPopUpClose.bind(this)}
                        title={t("History")}
                        component={this.getVitalHistory.bind(this, this.state.vitalHistory)}
                    />
                    : null
                }
                <Loader loaderOpen={this.state.isLoader} />
            </Box>
        )
    }
}


export default withTranslation()(NurseNotes)